<template>
    <div
        class="unsubscribe-message"
    >
        <div class="unsubscribe-message__icon">
            <span
                :class="{
                    'icon-success': info.status === 'ticketSuccess' || info.success,
                    'icon-attention': info.status !== 'ticketSuccess' && !info.success,
                }"
            ></span>
        </div>
        <template v-if="info.status === 'subscribeNotFound'">
            <h4 class="unsubscribe-message__title">
                Активных подписок не найдено
            </h4>
            <p class="unsubscribe-message__desc">
                Для указанного Вами номера <b>{{ data.phone | mask('+# ### ###-##-##') }}</b><br/>
                активных подписок в системе не найдено.
            </p>
        </template>
        <template v-else>
            <p
                class="unsubscribe-message__desc"
                v-html="info.messages.msg"
            ></p>
        </template>
        <div class="unsubscribe-message__action">
            <base-button
                back
                type="button"
                mode="black"
                @click.native="back()"
            >
                Вернуться
            </base-button>
        </div>
    </div>
</template>

<script>
import './unsubscribe-message.scss'

import mask from '@/helpers/string/setMask'

import Store from '@/store'

export default {
    name: 'UnsubscribeMessage',
    computed: {
        info() {
            return this.$route.params.info
        },
        data() {
            return this.$route.params.data
        }
    },
    methods: {
        back() {
            this.$router.push({ name: 'Unsubscribe' })
        },
    },
    filters: {
        mask
    },
    beforeRouteEnter(to, from, next) {
        const {
            info,
            data
        } = to.params

        if (info && data) {
            next()
        } else {
            Store.commit('application/load', false)
            next({
                name: 'Unsubscribe'
            })
        }
    }
}
</script>